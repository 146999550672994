.gx-layout-sider-header .gx-linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: auto;
  transition: all 0.3s ease-out;
}
pre {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0;
} 
.ant-menu-submenu > .ant-menu {
  background-color: #f1f1f1;
  border-radius: 0;
}

.gx-linebar {
  font-size: 30px;
}

.ant-table-body{
  min-height: calc( 100vh - 400px ) !important;

}
.gx-mb-4, .gx-my-4 {
  margin-bottom: 0rem !important;
}
.data-table-btn{
  float: right;
  font-size: 1.1rem;
}
.icon.icon-search-new{
  display: none;
}

 thead > tr > th{
  background-color: #1F7A84 !important;
  color: #fff !important;
  font-size: 1.1rem !important;
}

.ant-table-footer {
  color: #1F7A84 !important;
}

.rhap_container {
  width: 100%;
}

.icon-btns:hover{
  transform: scale(1.5);
  color: #1F7A84 !important;
}

.ant-layout-sider {
  background-color: #fffffc;
  color: #595959;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 190px !important;
  overflow: hidden;
}

.ant-layout-sider-collapsed {
  width: 60px !important;
}

.select_menu {
  z-index: 1111 !important;
}

.view-chapter-collapse .ant-collapse-extra {
  float: left !important;
  
}


.color-picker .ant-badge-status-dot,.color-badge .ant-badge-status-dot {
  width: 16px;
  height: 16px;

}

.color-picker .ant-badge-status-dot:hover{
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 2);
  cursor: pointer;

}
.color-picker{
  margin: 0;
  margin-right: 0 !important;
}

.table-icon {

  font-size: 16px;

}