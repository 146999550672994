h4 {
  color: #1E7A84;
  font-weight: 500;
  text-transform: capitalize !important;
}
.ant-modal-body {
  padding: 35px;
}

.chapter-title {
  text-align: center;
  color: #1E7A84;
  font-weight: 600;
  font-size: 1.6rem;
}
.req-astrick{
  color: red;
}
.upImg {
  border: 2px dashed rgb(172, 172, 172);
  padding: 2px;
  cursor: pointer;
}
.paragraph, .desc-para > p{
  font-size: 1.3rem;
  color: #595959;
  font-weight: 500;
}
.upImg:hover {
 transform: scale(1.1);
 border-color:#F18805;
}


.ant-form-item{
  padding: 10px;
}




.edit-subscription-modal .ant-modal-header{
  padding: 16px 64px 16px 44px;
    
}
.error{
  color: red;
}
.edit-subscription-modal  .icon-btn{
  color: #595959;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
}