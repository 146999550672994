.ReactTable {
  box-shadow: 1px 1px 5px #ddd !important;
  border: unset !important;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
  border: none;
  background-color:#1F7A84;
  color: #fff;
  font-weight:500;
  font-size: 1.1rem;
  height: 50px;
  text-transform: capitalize;
  padding-top: 1rem !important;
}
.ReactTable .rt-thead .rt-tr {
  text-align: center;
  background-color: white;
}
.ReactTable .rt-thead.-filters .rt-th {
  background-color: unset !important;

  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.ReactTable .rt-tbody .rt-td {
  background-color: white !important;
  text-align:center;
}


.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  text-align: center !important
}
.ReactTable .rt-thead.-filters .rt-th {
  background-color: unset !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.ReactTable .-pagination input, .ReactTable .-pagination select,
.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  color: #000
}

.ReactTable .rt-th, .ReactTable .rt-td{
  white-space : normal;
  text-overflow:unset;
}
.ReactTable .-pagination .-btn {
  color: #fff !important;
  background:#1F7A84 !important;
}

.ReactTable {
  margin: 20px 0;
}

.ReactTable .rt-thead.-filters select{
  font-size: 1rem;
}
.-sort-asc{
  box-shadow: inset 0 5px 0 0 rgba(243, 154, 42) !important;
}
.-sort-desc{
  box-shadow: inset 0 -5px 0 0 rgba(243, 154, 42) !important;
}



/* ------------------------- excel table ---------------------- */



#excel-table {
  text-align: center;
  border-collapse: collapse;
  border: none;
  width: 100%;
  margin: 20px auto;
  
}

#excel-table th{
  border: none;
  padding: 8px;
}
#excel-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
 
}


#excel-table tr:nth-child(even){background-color: white;}

/* #excel-table tr:hover {background-color: #ddd;} */

#excel-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #1F7A84;
  color: white;
}
