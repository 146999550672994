.AudioContainer {
  position: relative;
  user-select: none !important;
}
.AudioShader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 166, 28, 0.5);
  z-index: 1000;
}
.AudioShader h5 {
  padding: 2px 5px 2px 7px;
  display: inline-block;
  float: right;
  border-radius: 60px 0 0 60px;
}
.AudioShader h5 i {
  font-size: 1.5rem;
}


.AudioContainer *{
user-select: none !important;
-webkit-user-select: none !important;
-moz-user-select: none !important;
-ms-user-select: none !important;
-o-user-select: none !important;
outline: none !important
}

.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button  svg{
  color: #F18805 !important;
}
.rhap_button-clear.rhap_main-controls-button.rhap_forward-button svg ,
.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button svg,
.rhap_button-clear.rhap_repeat-button svg,
.rhap_button-clear.rhap_volume-button svg,
.rhap_time.rhap_current-time,
.rhap_time.rhap_total-time{
color: #F18805 !important;
}
.rhap_volume-indicator{
background: #F18805 !important;
}
.rhap_progress-filled {
background-color:#1F7A84 !important;
}
.rhap_progress-indicator{
  background: #258f9b !important;
}


.chapter_body div:first-child {
  text-align: right;
}
.time {
  color: #F18805;
}
